import React from 'react';
import { Helmet } from 'react-helmet-async';

const Meta = ({ title, description, keywords, url, ogType, ogImage, twitterCard, twitterImage }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      {keywords && <meta name="keywords" content={keywords} />}
      {url && <link rel="canonical" href={url} />}
      
      {/* Open Graph (OG) tags */}
      {url && <meta property="og:url" content={url} />}
      <meta property="og:type" content={ogType || "website"} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {ogImage && <meta property="og:image" content={ogImage} />}
      <meta property="og:site_name" content="Scotrand Studio" />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content={twitterCard || "summary_large_image"} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {twitterImage && <meta name="twitter:image" content={twitterImage} />}
      <meta name="twitter:site" content="@ScotrandStudio" />
      <meta name="twitter:creator" content="@ScotrandStudio" />
      
      {/* Additional meta tags */}
      <meta name="author" content="Scotrand Studio" />
      <meta name="robots" content="index, follow" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="icon" href={ogImage} sizes="32x32" />
      <link rel="icon" href={ogImage} sizes="192x192" />
      <link rel="apple-touch-icon" href={ogImage} />
    </Helmet>
  );
};

export default Meta;
