import React from 'react';
import { processcontent } from '../../assets/Contents';
import Images from '../../assets/images';
import * as Icons from 'react-icons/si'

const ProcessSection = () => {


 const getComponentIcon = (iconname) => {

    const IconComponent = Icons[`Si${iconname}`];
    return IconComponent || Icons.SiSimpleicons;

 }


  return (
    <div className='ProcessSection overflow-hidden'>
      <div className="container mx-auto px-4">
        <div className="process-content pt-16 lg:pt-48">
          <div className='discovery mb-9'>
            <h2 className='mb-4 text-3xl sm:text-5xl lg:text-6xl xl:text-7xlfont-semibold sm:font-normal  text-[#8CA9F5] relative w-fit'>
              Let’s Discovery
              <span className="absolute -right-28 bottom-0">
                <div className="bg-transparent lg:inline-block lg:bg-radial from-teal-300 via-gray-100 to-blue-300 blur-[10px] rounded-full rotate-[16.62deg]">
                  \\\\
                </div>
                <span className='text-white text-2xl hidden lg:block absolute left-[10px] lg:left-[20px] top-[20px]'>Start</span>
                <img src={Images.driftarrow} alt="driftarrow" loading='lazy'/>
              </span>
            </h2>
            <p className='text-lg sm:text-xl  lg:text-2xl xl:text-3xl text-white opacity-80 md:font-light'>
              {processcontent[0].description}
            </p>
          </div>

          <div className='designs flex flex-wrap mb-9'>
            <div className='w-full lg:w-4/12 mb-9 relative'>
              <img src={Images.designimg} alt="designimg" className='w-full' loading='lazy'/>
              <div className='w-full scale-125 sm:w-8/12 lg:w-6/12 h-full absolute top-1/4 lg:-top-1/4 left-1/2 -z-10 translate-x-[-50%] blur-[60px] from-[#003CDA28] bg-gradient-to-t to-[#191A26]'></div>
            </div>

            <div className='w-full lg:w-8/12 lg:pt-40'>
              <h2 className='mb-4 text-3xl sm:text-5xl lg:text-6xl xl:text-7xl font-semibold sm:font-normal  text-[#8CA9F5] relative w-fit'>
                Do Design’s
              </h2>
              <p className='text-lg sm:text-xl  lg:text-2xl xl:text-3xl text-white opacity-80 md:font-light'>
                {processcontent[1].description}
              </p>

              <div className='tools mt-12 flex flex-wrap gap-8'>
                {processcontent[1].tools.map(tool => {
                    const Iconcomponent = getComponentIcon(tool.iconname)
                    
                        return(
                  <div key={tool.id} className='flex flex-col gap-4 items-center  w-[90px]'>
                    <Iconcomponent className='icon text-white text-[32px]' />
                    <span className='ml-2 text-white'>{tool.name}</span>
                  </div>
)})}
              </div>
            </div>
          </div>

          <div className='pixelperfect flex flex-wrap flex-col-reverse lg:flex-row mb-9 '>
            
          <div className='w-full lg:w-9/12 lg:pt-40 relative'>
              <h2 className='mb-4 text-3xl sm:text-5xl lg:text-6xl xl:text-7xl font-semibold sm:font-normal text-[#8CA9F5] relative w-fit'>
              Pixel-Perfect Development 
              </h2>
              <p className='text-lg sm:text-xl  lg:text-2xl xl:text-3xl text-white opacity-80 md:font-light'>
                {processcontent[2].description}
              </p>
              <img src={Images.sidedesignimg} alt="designimg" className=' absolute -top-0 left-1/3 -z-10 opacity-5' loading='lazy'/>

              <div className='tools mt-12 flex flex-wrap gap-8'>
                {processcontent[2].tools.map(tool => {
                    const Iconcomponent = getComponentIcon(tool.iconname)
                    
                        return(
                  <div key={tool.id} className='flex flex-col gap-4 items-center w-[90px]'>
                    <Iconcomponent className='icon text-white text-[32px]' />
                    <span className=' text-white'>{tool.name}</span>
                  </div>
)})}
              </div>
            </div>
            <div className='w-full lg:w-3/12 mb-9 relative'>
              <img src={Images.pexelperfectimg} alt="designimg" className='w-full' loading='lazy'/>
              <div className='w-full sm:w-[500px]  sm:h-[500px] absolute top-1/4 lg:-top-1/2 left-1/2 -z-10 translate-x-[-50%] blur-[60px] from-[#003CDA28] bg-gradient-to-t to-[#191A26]'></div>
            </div>

          </div>
          <div className='delivery flex flex-wrap mb-9'>
            {/* <div className='w-full lg:w-4/12 mb-9 relative'>
              <img src={Images.designimg} alt="designimg" className='w-full' loading='lazy'/>
              <div className='w-full scale-125 sm:w-8/12 lg:w-6/12 h-full absolute top-1/4 lg:-top-1/4 left-1/2 -z-10 translate-x-[-50%] blur-[60px] from-[#003CDA28] bg-gradient-to-t to-[#191A26]'></div>
            </div> */}

            <div className='w-full lg:w-12/12 lg:pt-10'>
              <h2 className='mb-4 text-3xl sm:text-5xl lg:text-6xl xl:text-7xl font-semibold sm:font-normal  text-[#8CA9F5] relative w-fit'>
              Delivery & Support
              </h2>
              <p className='text-lg sm:text-xl  lg:text-2xl xl:text-3xl text-white opacity-80 md:font-light'>
                {processcontent[3].description}
              </p>

              <div className='tools mt-12 flex flex-wrap gap-8'>
                {processcontent[3].tools.map(tool => {
                    const Iconcomponent = getComponentIcon(tool.iconname)
                    
                        return(
                  <div key={tool.id} className='flex flex-col gap-4 items-center w-[90px]'>
                    <Iconcomponent className='icon text-white text-[32px]' />
                    <span className=' text-white'>{tool.name}</span>
                  </div>
)})}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcessSection;
