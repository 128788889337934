import React, { useState } from 'react';
import axios from 'axios';
import Images from '../../assets/images';
import ReactGA from 'react-ga';

const ContactSection = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('Fill all mandatory fields');

  const serviceID = process.env.REACT_APP_SERVICE_ID;
  const templateID = process.env.REACT_APP_TEMPLATE_ID;
  const publicKey = process.env.REACT_APP_PUBLIC_KEY;
  const Tracking_id = process.env.REACT_APP_TRACKING_ID;

  ReactGA.initialize(Tracking_id);

  const resetForm = () => {
    setName('');
    setEmail('');
    setMobile('');
    setMessage('');
    setErrorMessage('Fill all mandatory fields');
  };

  const handleForm = async (e) => {
    e.preventDefault();

    ReactGA.event({
      category: 'Form Submission',
      action: 'Contact Form Submitted'
    });

    const data = {
      service_id: serviceID,
      template_id: templateID,
      user_id: publicKey,
      template_params: {
        fullname: name,
        email: email,
        mobile: mobile,
        message: message,
      },
    };

    try {
      const response = await axios.post('https://api.emailjs.com/api/v1.0/email/send', data);
      console.log('Form submission successful:', response.data);

      // Reset form fields after successful submission
      resetForm();

      alert('Form submitted successfully!');
    } catch (error) {
      console.error('Form submission error:', error);
      setErrorMessage(error.message);
    }
  };

  return (
    <div className='Contact my-12' id='contact'>
      <div className="container mx-auto">
        <div className="contact-container px-6">
          <h2 className='mb-12 lg:mb-4 text-3xl sm:text-5xl lg:text-6xl xl:text-7xl font-semibold sm:font-normal text-[#8CA9F5] relative w-fit'>
            Let’s Work Together, because Suggestion is Good.
          </h2>
          <div className="flex flex-col gap-y-9 lg:flex-row md:gap-0 items-center">
            <div className="w-full lg:w-7/12 md:mr-9">
              <form onSubmit={handleForm} action='post'>
                <div className='flex flex-col sm:flex-row items-center gap-4 mb-4'>
                  <input
                    type="text"
                    name="name"
                    placeholder='Full Name'
                    className='w-full bg-[#8caaf5]/15 outline-none px-4 py-2 rounded-lg text-[#8CA9F5]'
                    value={name}
                    required
                    onChange={(e) => setName(e.target.value)}
                  />
                  <input
                    type="tel"
                    name="mobile"
                    placeholder='Mobile'
                    className={`w-full bg-[#8caaf5]/15 outline-none px-4 py-2 rounded-lg text-[#8CA9F5] `}
                    value={mobile}
                    required
                    onChange={(e) => setMobile(e.target.value)}
                  />
                </div>
                <div className='flex flex-col sm:flex-row items-center gap-4 mb-4'>
                  <input
                    type="email"
                    name="email"
                    placeholder='Email'
                    className='w-full bg-[#8caaf5]/15 outline-none px-4 py-2 rounded-lg text-[#8CA9F5]'
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <textarea
                  name="message"
                  placeholder='Write your Message here.'
                  rows={5}
                  className='w-full block bg-[#8caaf5]/15 outline-none px-4 py-2 rounded-lg text-[#8CA9F5] mb-4 resize-none'
                  spellCheck='true'
                  value={message}
                  required
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
                <div className='flex flex-col lg:flex-row lg:items-center gap-4 justify-between'>
                  <span className='block w-full text-base text-[#8CA9F5]'>{errorMessage}</span>
                  <div className="flex flex-wrap items-center w-full gap-x-4 lg:contents">
                    <button type='submit' className='inline-block transition-all duration-200 px-4 py-2 rounded-xl text-[#8CA9F5] border-2 border-[#8CA9F5] hover:bg-[#8CA9F5] hover:text-[#1B1818] text-sm'>Submit</button>
                    <button type='reset' className='inline-block transition-all duration-200 px-4 py-2 rounded-xl text-red-600 border-2 border-red-600 hover:bg-red-600 hover:text-[#1B1818] text-sm' onClick={resetForm}>Clear</button>
                  </div>
                </div>
              </form>
            </div>
            <div className="w-full lg:w-5/12 relative">
              <img src={Images.coffeeform} alt="Coffee with laptop" className='mx-auto'  loading='lazy'/>
              <div className='w-full h-full sm:w-[500px] sm:h-[500px] absolute top-1/3 lg:-top-1/2 right-0 lg:left-1/2 -z-10 translate-x-[-50%] blur-[60px] from-[#003CDA28] bg-gradient-to-t to-[#191A26] '></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
