import React, { useEffect, useState } from 'react';
import Images from '../../assets/images';
import { CgMenuGridR, CgClose } from 'react-icons/cg';
import { Link } from 'react-router-dom';

const scrollToSection = (id) => {
  const section = document.querySelector(id);
  if (section) {
    section.scrollIntoView({ behavior: 'smooth' });
  }
};

const Header = () => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  useEffect(() => {
    const body = document.querySelector('body');
    body.style.overflow = isMenuOpened ? 'hidden' : 'inherit';
    return () => {
      body.style.overflow = 'inherit';
    };
  }, [isMenuOpened]);

  const handleMenu = () => {
    setIsMenuOpened(!isMenuOpened);
  };

  const handleLinkClick = () => {
    if (window.innerWidth < 768) {
      setIsMenuOpened(false);
    }
  };

  return (
    <div className={`Header z-20 bg-[#161D35] md:bg-transparent md:backdrop-blur-0 border-[#8CA9F5]/30 border border-b-2 md:border-none transition-all duration-200 ${isMenuOpened ? 'h-screen' : 'h-auto'} md:h-auto sm:relative w-full top-0 left-0`}>
      <div className='container mx-auto'>
        <div className='flex items-center justify-between pt-6 md:pt-12 pb-4 px-4 relative'>
          <div className='mbltop flex items-center justify-between w-full md:w-auto'>
            <Link to='/' onClick={() => setIsMenuOpened(false)}>
              <div className='logo relative z-10'>
                <img src={Images.logo} alt='scotrand logo' loading='lazy' />
              </div>
            </Link>
            <div className='hamburger md:hidden relative z-10 transition-all duration-300' onClick={handleMenu}>
              {isMenuOpened ? <CgClose className='icons text-4xl text-red-600' /> : <CgMenuGridR className='icons text-4xl text-[#8CA9F5]' />}
            </div>
          </div>

          <div className={`menu flex z-[9] sm:bg-transparent ${isMenuOpened ? 'h-screen bg-[#1B1818]' : 'h-0 overflow-hidden'} duration-700 md:flex-row flex-col transition-all items-start md:items-center md:relative md:top-0 md:left-0 top-[0px] left-0 absolute bg-[#1B1818] w-full md:w-auto md:h-auto`}>
            <div className={`submenu md:flex-row md:items-center flex flex-col w-full items-start md:p-0 p-4 pt-[100px] gap-6 mb-6 md:m-0`}>
              <Link className='text-white transition-all duration-200 hover:text-[#8CA9F5] text-3xl md:text-sm' to='/' onClick={() => { setIsMenuOpened(false); handleLinkClick(); }}>
                Home
              </Link>
              <Link className='text-white transition-all duration-200 hover:text-[#8CA9F5] text-3xl md:text-sm' to='/works' onClick={() => { setIsMenuOpened(false); handleLinkClick(); }}>
                Works
              </Link>
              <Link className='text-white transition-all duration-200 hover:text-[#8CA9F5] text-3xl md:text-sm' to='/about' onClick={() => { setIsMenuOpened(false); handleLinkClick(); }}>
                About
              </Link>
              <Link className='text-white transition-all duration-200 hover:text-[#8CA9F5] text-3xl md:text-sm' to='/blogs' onClick={() => { setIsMenuOpened(false); handleLinkClick(); }}>
                Blogs
              </Link>
            </div>
            <div className='menu-cta flex items-center gap-6 md:hidden md:mb-0 mb-6'>
              <Link
                className='transition-all duration-200 primaryColor bg-transparent px-4 py-2 rounded-xl border-2 border-transparent hover:border-[#8CA9F5] text-xl'
                to='/#contact'
                onClick={() => {
                  setIsMenuOpened(false);
                  scrollToSection('#contact');
                }}>
                Let’s Chat
              </Link>
              <Link
                className='transition-all duration-200 px-4 py-2 rounded-xl text-[#1B1818] bg-[#8CA9F5] border-2 border-transparent hover:border-[#8CA9F5] hover:bg-transparent hover:text-[#8CA9F5] text-xl'
                to='/#contact'
                onClick={() => {
                  setIsMenuOpened(false);
                  scrollToSection('#contact');
                }}>
                Meet up
              </Link>
            </div>
          </div>
          <div className='menu-cta hidden md:flex items-center gap-6'>
            <Link
              className='transition-all duration-200 primaryColor bg-transparent px-4 py-2 rounded-xl border-2 border-transparent hover:border-[#8CA9F5] text-sm'
              to='/#contact'
              onClick={() => scrollToSection('#contact')}>
              Let’s Chat
            </Link>
            <Link
              className='transition-all duration-200 px-4 py-2 rounded-xl text-[#1B1818] bg-[#8CA9F5] border-2 border-transparent hover:border-[#8CA9F5] hover:bg-transparent hover:text-[#8CA9F5] text-sm'
              to='/#contact'
              onClick={() => scrollToSection('#contact')}>
              Meet up
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
