import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Meta from '../components/Meta'; 
import { metaitems } from '../assets/Contents';

const ErrorPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when component mounts
  }, []);

  return (
    <>
      <Meta
        title="Page Not Found - Scotrand Studio"
        description="Oops! Something went wrong. The page you are looking for may have been removed or doesn't exist."
        keywords="page not found, error page, Scotrand Studio"
         url="https://www.scotrandstudio.com"
        ogType="website"      
        ogImage={metaitems.ogimg}
        twitterCard="summary_large_image"
        twitterImage={metaitems.tweetimg}
      />
      <div className="ErrorPage py-12 flex items-center">
        <div className="container mx-auto p-4">
          <h1 className="text-6xl font-bold text-blue-600 mb-4">Oops! Something went wrong.</h1>
          <p className="text-lg text-gray-200 mb-8">The page you are looking for may have been removed or doesn't exist.</p>
          <Link to="/" className="text-blue-600 hover:underline">Go back to home</Link>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
