export const scrollToSection = (id) => {
    const element = document.getElementById(id);
    // console.log('Scrolling to:', id, 'Element found:', !!element);
    if (element) {
      let yOffset = 0;
      if (window.innerWidth < 768) {
        yOffset = -100;
      }
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };
  