import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import ErrorPage from './pages/ErrorPage';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import BlogDetail from './components/Blog/BlogDetail';
import ReactGA from 'react-ga';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Disclaimer from './pages/Disclaimer';
import Blogs from './components/Blog/Blogs';
import Works from './pages/Works';

const Tracking_id = process.env.REACT_APP_TRACKING_ID;

const App = () => {
  useEffect(() => {
    ReactGA.initialize(Tracking_id);
    // Initial page view
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  // Custom hook to track location changes
  const TrackPageViews = () => {
    let location = useLocation();
    useEffect(() => {
      ReactGA.pageview(location.pathname + location.search);
    }, [location]);
    return null;
  };

  return (
    <Router>
      <TrackPageViews />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog/:id" element={<BlogDetail />} />
        <Route path='/blogs' element={<Blogs/>}/>
        <Route path='/works' element={<Works/>}/>
        <Route path="*" element={<ErrorPage />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/policy" element={<PrivacyPolicy/>} />

      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
