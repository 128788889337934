import React from 'react';
import Images from '../../assets/images';
import { Link } from 'react-router-dom';
import { footer } from '../../assets/Contents';
import * as Icons from 'react-icons/si';
import { scrollToSection } from './Scroll';

const Footer = () => {
  const getComponentIcon = (iconname) => {
    const IconComponent = Icons[`Si${iconname}`];
    return IconComponent || Icons.SiSimpleicons;
  };

  return (
    <div className='Footer mt-16 border-t-2 pt-8 border-white/20'>
      <div className='container mx-auto'>
        <div className='footer-container flex flex-wrap gap-8 items-start justify-between px-4 mb-9'>
          <div className='logo'>
            <Link to='/'>
              <img src={Images.logo} alt='Logo' loading='lazy' />
            </Link>
          </div>
          <div className='flex items-start flex-wrap sm:flex-nowrap w-full sm:w-auto gap-x-14 gap-y-8 md:gap-8'>
            <div>
              <p className='text-[#BAB9B9] text-lg font-medium uppercase'>Site</p>
              <div className='flex flex-col gap-2'>
                {footer.sitelinks.map((item, index) => (
                  <Link
                    key={index}
                    className='text-[#BAB9B9] transition-all duration-200 hover:text-[#8CA9F5] text-sm'
                    to={item.linkname}
                    onClick={() => scrollToSection(item.unquieid)}>
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
            <div>
              <p className='text-[#BAB9B9] text-lg font-medium uppercase'>Important</p>
              <div className='flex flex-col gap-2'>
                {footer.importantlinks.map((item, index) => (
                  item.linkname ? (
                    <Link
                      key={index}
                      className='text-[#BAB9B9] transition-all duration-200 hover:text-[#8CA9F5] text-sm'
                      to={item.linkname}
                      onClick={() => scrollToSection(item.unquieid)}>
                      {item.name}
                    </Link>
                  ) : (
                    <span key={index} className='text-[#BAB9B9] text-sm'>
                      {item.name}
                    </span>
                  )
                ))}
              </div>
            </div>
            <div>
              <p className='text-[#BAB9B9] text-lg font-medium uppercase'>Mail me!</p>
              <div className='flex flex-col gap-2'>
                <Link
                  to={`mailto:${footer.emailid}`}
                  className='text-[#BAB9B9] transition-all duration-200 hover:text-[#8CA9F5] text-sm'>
                  {footer.emailid}
                </Link>
                <Link
                  to={`tel:+91${footer.mobile}`}
                  className='text-[#BAB9B9] transition-all duration-200 hover:text-[#8CA9F5] text-sm'>
                  +91 {footer.mobile}
                </Link>
              </div>
            </div>
          </div>
          <div className='w-full lg:w-auto'>
            <div className='flex items-center gap-4 justify-center w-full'>
              {footer.socialicons.map((item) => {
                const IconComponent = getComponentIcon(item.iconname);
                return (
                  <Link to={item.site} target='_blank' key={item.id}>
                    <div
                    
                    className='flex flex-col gap-4 items-center cursor-pointer w-[90px] lg:w-auto group transition-all duration-200'>
                    <IconComponent className='icon text-[#BAB9B9] group-hover:text-[#8CA9F5] text-[32px]' />
                    <span className='text-[#BAB9B9] group-hover:text-[#8CA9F5]'>
                      {item.name}
                    </span>
                  </div>
                  </Link>
                
                );
              })}
            </div>
          </div>
        </div>

        <div className='flex flex-wrap text-center justify-center md:text-left md:justify-between items-center mb-3 gap-2'>
          {footer.copyrights.map((item, index) => (
            <p key={index} className='text-[#BAB9B9] text-sm'>
              {item}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;
