import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { blogs} from '../../assets/Contents';
import Meta from '../Meta';

const BlogDetail = () => {
  const { id } = useParams();
  const blog = blogs[id];

  let prevBlog = parseInt(id);
  prevBlog--;
  let nextBlog = parseInt(id);
  nextBlog++;

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when component mounts
  }, [id]); // Trigger scroll when id changes

  // if (!blog) {
  //   return (
  //     <div className="container mx-auto">
  //       <p>Blog post not found.</p>
  //     </div>
  //   );
  // }

  return (
    <>
      <Meta
        title={`${blog.title} - Scotrand Studio`}
        description={blog.description}
        keywords={blog.keywords} // Assuming you have keywords in your blog object
        url={`https://www.scotrandstudio.com/blog/${id}`}
        ogType="article"
        ogImage={blog.image} // Assuming each blog object has an image property
        twitterCard="summary_large_image"
        twitterImage={blog.image}
      />

      <article className='Blog mt-12 lg:mt-4'>
        <div className="container mx-auto md:w-8/12">
          <div className="blog-container px-4">
            <h3 className='text-white font-semibold text-3xl sm:text-4xl lg:text-5xl mb-6'>{blog.title}</h3>
            <div className="blog-img overflow-hidden rounded-lg mb-8">
              <img src={blog.image} alt={blog.title} className='w-full h-[200px] lg:h-[350px] object-cover' loading='lazy' />
            </div>
           
            <p className='text-base md:text-lg lg:text-base text-white opacity-80'>{blog.description}</p>
            <hr className='my-4 border-[#8CA9F5]' />
            <p className='text-2xl lg:text-3xl text-[#8CA9F5] mt-2 mb-4'>Related Blogs</p>
            <div className='flex items-center justify-between'>
              <Link className={`transition-all duration-200 bg-transparent px-4 text-[#8CA9F5] py-2 rounded-xl border-2 border-[#8CA9F5] lg:border-transparent hover:border-[#8CA9F5] text-xl ${(prevBlog < 0) ? 'opacity-50 pointer-events-none' : ''}`} to={`/blog/${prevBlog}`}>prev</Link>
              <Link className={`transition-all duration-200 bg-transparent px-4 text-[#8CA9F5] py-2 rounded-xl border-2 border-[#8CA9F5] lg:border-transparent hover:border-[#8CA9F5] text-xl ${(nextBlog > blogs.length - 1) ? 'opacity-50 pointer-events-none' : ''}`} to={`/blog/${nextBlog}`}>next</Link>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}

export default BlogDetail;
