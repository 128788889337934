import React, { useEffect, useState } from 'react'
import {herocontent, heroprojects} from '../../assets/Contents'
import Images from '../../assets/images'

const HeroSection = () => {
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth)
 


  useEffect(()=> {
  
    window.addEventListener('resize', () => {
      setDeviceWidth(window.innerWidth)
    })
    // console.log(deviceWidth)
  },[deviceWidth])

  const getTopValue = (index, arrayLength, devicewidth) => {
    let midValue = Math.floor(arrayLength / 2);
    let topValue = 0;
    if(devicewidth >= 1024){
       topValue = (index <= midValue) ? (index + 1) * 45 : (arrayLength - index) * 45;   
      
    }
    else if (devicewidth < 1024 && devicewidth > 767){
      topValue = ((index % 3) + 1) * 45;
    }
    else if (devicewidth <= 767){
       topValue = (index%2 !== 0) ? 30 : 0;  
    }
    return topValue
  }

  return (
    <section className='HeroSection '>
        <div className="container mx-auto">
         <div className='hero-content text-center pt-32 relative'>
         {/* <span className='mb-4 font-light text-base sm:text-2xl text-white block'>{herocontent.welcomeTitle}</span> */}
            <h1 className='mb-4 text-3xl font-semibold sm:font-normal sm:text-5xl lg:text-7xl text-[#8CA9F5] lg:w-10/12 mx-auto'>{herocontent.title}</h1>
            <p className='mb-4 text-white font-light  text-xl lg:text-2xl opacity-85'>{herocontent.description}</p>
         </div>
        </div>
        <div className='w-full  sm:w-8/12 lg:w-6/12 h-full  absolute -top-1/3 left-1/2 -z-10 translate-x-[-50%]  blur-[60px] from-[#003CDA28] bg-gradient-to-t to-[#191A26]'></div>
   
   <div className='relative container mx-auto  overflow-hidden lg:overflow-visible'>
   <div className='px-4 mt-14 md:px-8 lg:px-12 grid grid-cols-2 gap-x-3 md:grid-cols-3 lg:grid-cols-5 xl:flex   xl:justify-around xl:gap-x-4 '>
                {
                    heroprojects.map((item, index, array) =>{
                    

                                   const topValue = getTopValue(index,array.length, deviceWidth)
                   
                        return (
                            <div key={item.id}  style={{ marginTop: `${topValue}px` }} className={` h-fit rounded-xl overflow-hidden bg-gradient-to-b from-[#8CA9F5] to-[#2359E6] p-[2px]`}>
                            <img src={item.image} className='w-full h-full rounded-xl  ' alt={item.title} loading='lazy' />
                               </div>
                  
                     
                        )
                    })
                }
                   
            </div>
   
       <div className='w-full  sm:w-8/12 lg:w-6/12   opacity-20 absolute bottom-[-0%] left-3/4 md:left-1/2 lg:bottom-[-50%] -z-10 translate-x-[-25%] md:translate-x-[-50%] lg:translate-x-[-50%] xl:translate-x-[-50%] '>
        <img src={Images.pokeball} alt={Images.pokeball} loading='lazy' />
        </div>
      
   </div>

           
        
      
    </section>
  )
}

export default HeroSection