import React, { useEffect, useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { heroprojects } from '../../assets/Contents';
import { Link } from 'react-router-dom';

const ShowCase = () => {
  const images = heroprojects.flatMap(project => project.image);
  const itemsContainerRef = useRef(null);
  const controls = useAnimation();

  useEffect(() => {
    const itemContainer = itemsContainerRef.current;
    if (!itemContainer) return;

    const gap = 8;
    const itemWidth = itemContainer.firstChild.clientWidth; // Assuming all items have the same width
    const totalWidth = (itemWidth + gap) * images.length;

    const infiniteScrolling = async () => {
      await controls.start({
        x: [ 0, -totalWidth + (gap * images.length)], // Start from a negative position to show duplicates initially
        transition: {
          x: {
            duration: totalWidth / 100, // Adjust duration as per your need
            repeat: Infinity,
            ease: 'linear',
          },
        },
      });
    };

    infiniteScrolling();
  }, [controls, images.length]);

  // Combine images into a single variable to avoid duplicating map calls
  const allItems = images.map((item, index) => (
    <Link to='/projects' key={index}>
      <div className='w-40 lg:w-72 h-48 rounded-xl overflow-hidden bg-gradient-to-b from-[#8CA9F5] to-[#2359E6] p-[2px] transition-all duration-200 lg:hover:scale-105 m-2'>
        <img
          src={item}
          alt={`Project ${index + 1}`} // Updated alt text to be more descriptive
          className='w-full flex-shrink-0 rounded-xl h-full object-top object-cover'
          loading='lazy'
        />
      </div>
    </Link>
  ));

  return (
    <div className='ShowCase overflow-hidden w-full flex gap-2'>
      <motion.div className='flex' animate={controls} ref={itemsContainerRef}>
        {allItems}
        {allItems} {/* Duplicates the items for seamless scrolling */}
      </motion.div>
    </div>
  );
};

export default ShowCase;
