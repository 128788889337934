import React, { useEffect } from 'react';
import HeroSection from '../components/LandingPage/HeroSection';
import ProcessSection from '../components/LandingPage/ProcessSection';
import ClientSection from '../components/LandingPage/ClientSection';
import BlogPost from '../components/Blog/BlogPost';
import ContactSection from '../components/LandingPage/ContactSection';
import ShowCase from '../components/common/ShowCase';
import Meta from '../components/Meta';
import { metaitems } from '../assets/Contents';


const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when component mounts
  }, []);
  return (
    <>
      <Meta
        title="Scotrand Studio - Transforming Ideas into Visual Masterpieces"
        description="Innovative design solutions for your business. Explore our portfolio and discover our creative process."
        keywords="Scotrand Studio, design agency, branding, UI/UX design, digital marketing"
        url="https://www.scotrandstudio.com"
        ogType="website"
        ogImage={metaitems.andriodchrome512}
        twitterCard="summary_large_image"
        twitterImage={metaitems.tweetimg}
      />

      <div className='Home'>
        <HeroSection />
        <ProcessSection />
        <ClientSection />
        <BlogPost visiblePost = {3} showAll={false} />
        <ContactSection />
     
        <ShowCase />
      </div>
    </>
  );
};

export default Home;
