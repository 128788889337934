import Images from './images/index'

export const herocontent = {
    welcomeTitle : 'Hello, Buddy',
    title: 'Transforming Ideas into Visual Masterpieces',
    description: 'Innovative Design Solutions for Your Business'
}

export const heroprojects = [
    {
    id:1,
    title: "Digital Desiging",
    description: "there is few descriptions.",
    image: Images.herosecimg1,
},
{
    id:2,
    title: "Branding Agency",
    description: "there is few descriptions.",
    image: Images.herosecimg2,
},
{
    id:3,
    title: "Portfolio",
    description: "there is few descriptions.",
    image: Images.herosecimg3,
},
{
    id:4,
    title: "E-Commerce",
    description: "there is few descriptions.",
    image: Images.herosecimg4,
},
{
    id:5,
    title: "Styler",
    description: "there is few descriptions.",
    image: Images.herosecimg5,
}
]
export const processcontent = [
    {
id: 1,
title:`Let’s Discovery`,
description: `Understanding your needs and goals. We begin by listening to your ideas, analyzing your brand, and defining your project's scope and objectives.`,
},
{
    id: 2,
    title:`Let’s Discovery`,
    description: `Crafting visually stunning, user-centric designs. Our team develops creative concepts and detailed design mockups that align with your vision and brand identity.`,
    tools: [
        {
            id: 1,
            name: 'Figma',
            iconname: 'Figma',
            iconsite: 'https://simpleicons.org/'
        },
        {
            id: 2,
            name: 'Photoshop',
            iconname: 'Adobephotoshop',
            iconsite: 'https://simpleicons.org/'
        },
        {
            id: 3,
            name: 'Adobe XD',
            iconname: 'Adobexd',
            iconsite: 'https://simpleicons.org/'
        },
    
    ],
    
    },
    {
        id: 3,
        title:`Delivery & Support`,
        description: `Ensuring a smooth handover and ongoing support. We deliver final assets, provide guidance on implementation, and offer continuous support to ensure your satisfaction.`,
        tools: [
            {
                id: 1,
                name: 'HTML 5',
                iconname: 'Html5',
                iconsite: 'https://simpleicons.org/'
            },
            {
                id: 2,
                name: 'CSS 3',
                iconname: 'Css3',
                iconsite: 'https://simpleicons.org/'
            },
            {
                id: 3,
                name: 'JavaScript',
                iconname: 'Javascript',
                iconsite: 'https://simpleicons.org/'
            },
            {
                id: 4,
                name: 'React JS',
                iconname: 'React',
                iconsite: 'https://simpleicons.org/'
            },
            {
                id: 5,
                name: 'Tailwind',
                iconname: 'Tailwindcss',
                iconsite: 'https://simpleicons.org/'
            },
            {
                id: 6,
                name: 'Node',
                iconname: 'Nodedotjs',
                iconsite: 'https://simpleicons.org/'
            },
            {
                id: 7,
                name: 'Npm',
                iconname: 'Npm',
                iconsite: 'https://simpleicons.org/'
            },
            {
                id: 8,
                name: 'Git',
                iconname: 'Git',
                iconsite: 'https://simpleicons.org/'
            },
           
        
        ],
        
        },
    
    {
        id: 4,
        title:`Delivery & Support`,
        description: `Ensuring a smooth handover and ongoing support. We deliver final assets, provide guidance on implementation, and offer continuous support to ensure your satisfaction.`,
        tools: [
            {
                id: 1,
                name: 'Reviews',
                iconname: 'Gmail',
                iconsite: 'https://simpleicons.org/'
            },
            {
                id: 2,
                name: 'Q & A',
                iconname: 'Answer',
                iconsite: 'https://simpleicons.org/'
            },
            {
                id: 3,
                name: 'Supports',
                iconname: 'Headphonezone',
                iconsite: 'https://simpleicons.org/'
            },
           
        
        ],
        
        },

]
export const clientcontent =  {
    id: 1,
    title: 'Best at',
    description: ['Chance are low to get the top one, be like a tortoise not like a Rabbit', 'Never Lose the Patient, the process are slow but the Returns is Perfect'],
    image: Images.trophy,
    clientlogos: [{
        id: 1,
        name: 'logo',
        image: Images.brandlogo1,
    },
    {
        id: 2,
        name: 'logo',
        image: Images.brandlogo2,
    },
    {
        id: 3,
        name: 'logo',
        image: Images.brandlogo3,
    },
    {
        id: 4,
        name: 'logo',
        image: Images.brandlogo4,
    },
    {
        id: 5,
        name: 'logo',
        image: Images.brandlogo5,
    },
    {
        id: 6,
        name: 'logo',
        image: Images.brandlogo6,
    },
    {
        id: 7,
        name: 'logo',
        image: Images.brandlogo7,
    },
    {
        id: 8,
        name: 'logo',
        image: Images.brandlogo8,
    },
    // {
    //     id: 9,
    //     name: 'logo',
    //     image: Images.brandlogo9,
    // },
]
}
export const blogs = [
    {
      id: 0,
      date: "2024-01-15",
      title: "Exploring Modern UI Design Trends",
      description: "An in-depth look at the latest trends in UI design and how they can enhance user experience. From minimalistic layouts to bold typography, explore how these trends are shaping the way users interact with digital products. Learn about the importance of user-centered design and how incorporating the latest trends can lead to more engaging and accessible interfaces.",
      image: Images.blog1
    },
    {
      id: 1,
      date: "2024-02-10",
      title: "Mastering CSS Grid Layout",
      description: "A comprehensive guide to CSS Grid Layout for creating responsive and flexible web designs. Understand the fundamentals of grid systems and how to utilize them to create complex layouts with ease. This guide covers everything from basic grid properties to advanced techniques, ensuring you have the skills needed to implement responsive designs that look great on any device.",
      image: Images.blog2
    },
    {
      id: 2,
      date: "2024-03-05",
      title: "UI/UX Best Practices for Mobile Apps",
      description: "Essential tips and best practices for designing intuitive and user-friendly mobile applications. Discover the key elements of mobile UX design, including navigation, touch interactions, and visual hierarchy. Learn how to create seamless experiences that keep users engaged and coming back to your app. This article provides actionable insights for enhancing mobile app usability and performance.",
      image: Images.blog3
    },
    {
      id: 3,
      date: "2024-03-25",
      title: "Introduction to Front-End Development",
      description: "An overview of front-end development, including key concepts, tools, and technologies. This article introduces beginners to the world of front-end development, explaining the roles of HTML, CSS, and JavaScript. Learn about the latest frameworks and libraries that simplify development and how to choose the right tools for your projects. Start your journey into front-end development with confidence.",
      image: Images.blog4
    },
    {
      id: 4,
      date: "2024-04-12",
      title: "Creating Seamless User Experiences",
      description: "Strategies and techniques for designing seamless and engaging user experiences. Delve into the principles of UX design, focusing on user flow, interaction design, and usability testing. Discover how to identify user needs and create interfaces that provide a smooth, intuitive experience. This article highlights the importance of empathy in design and offers practical tips for achieving user satisfaction.",
      image: Images.blog5
    },
    {
      id: 5,
      date: "2024-05-08",
      title: "Advanced JavaScript Techniques",
      description: "Exploring advanced JavaScript techniques for building dynamic and interactive web applications. From closures and asynchronous programming to modern ES6+ features, this article covers essential topics for experienced developers. Learn how to write more efficient and maintainable code, optimize performance, and leverage JavaScript's capabilities to enhance the functionality and interactivity of your web applications.",
      image: Images.blog6
    },
    {
      id: 6,
      date: "2024-05-30",
      title: "The Future of UI/UX Design",
      description: "Predictions and insights into the future of UI/UX design and its impact on web development. Explore emerging trends such as voice interfaces, AI-driven design, and augmented reality. Understand how these innovations are shaping the future of digital experiences and what skills designers need to stay ahead. This article provides a glimpse into the evolving landscape of UI/UX design and offers guidance on adapting to upcoming changes.",
      image: Images.blog7
    },
    {
      id: 7,
      date: "2024-06-15",
      title: "Responsive Design with Tailwind CSS",
      description: "How to use Tailwind CSS to create responsive designs that adapt to various screen sizes. Learn the principles of responsive design and how Tailwind's utility-first approach simplifies the process. This guide covers setting up Tailwind, creating breakpoints, and utilizing responsive utilities to ensure your designs look great on any device. Gain the confidence to build flexible and scalable layouts with Tailwind CSS.",
      image: Images.blog8
    },
    {
      id: 8,
      date: "2024-07-01",
      title: "Designing for Accessibility",
      description: "Important considerations and best practices for designing accessible websites and applications. Understand the principles of inclusive design and how to ensure your digital products are usable by everyone, including people with disabilities. This article provides practical tips for implementing accessibility features, testing for compliance, and creating a more inclusive web. Learn how to enhance your designs to meet accessibility standards and improve user experience for all.",
      image: Images.blog9
    },
    {
      id: 9,
      date: "2024-07-20",
      title: "Prototyping Tools for UI/UX Designers",
      description: "An overview of popular prototyping tools that can help streamline the UI/UX design process. Discover the benefits of prototyping and how it can save time and resources during the design phase. This article reviews top tools like Figma, Sketch, and Adobe XD, comparing their features and use cases. Learn how to choose the right prototyping tool for your workflow and enhance your design process.",
      image: Images.blog10
    },
    {
      id: 10,
      date: "2024-08-05",
      title: "Integrating Animation in Web Design",
      description: "Techniques for integrating animations into web designs to enhance user interaction and engagement. Understand the principles of animation and how to apply them to create dynamic, engaging interfaces. This article covers tools and libraries for implementing animations, as well as best practices for ensuring performance and accessibility. Learn how to use animations effectively to improve the overall user experience.",
      image: Images.blog11
    },
    {
      id: 11,
      date: "2024-08-22",
      title: "The Role of Front-End Developers in Agile Teams",
      description: "Exploring the critical role front-end developers play in agile development teams and workflows. Understand the responsibilities of front-end developers in agile environments, including collaboration, continuous integration, and iterative development. This article provides insights into the agile methodologies and how front-end developers can contribute to the success of projects. Learn strategies for effective communication and collaboration within agile teams.",
      image: Images.blog12
    }
  ];
  
  export const footer = {
   
    sitelinks : [{
        id: 1,
        name: 'Home',
        linkname: '/',
        unquieid: '',
    },
    {
        id: 2,
        name: 'About',
        linkname: 'about',
        unquieid: '',
    },
    {
        id: 3,
        name: 'Works',
        linkname: 'works',
        unquieid: '',
    },
    
    {
        id: 4,
        name: 'Contact',
        linkname: '#contact',
        unquieid: 'contact',
    },
],

    importantlinks : [
        {
            id: 1,
            name: 'Privacy Policy',
            linkname: 'policy',
            unquieid: '',
        },
        {
            id: 2,
            name: 'Disclaimer',
            linkname: 'disclaimer',
            unquieid: '',
        },
        {
            id: 3,
            name: 'Case Studies',
            linkname: 'casestudies',
            unquieid: '',
        },
    ],
    emailid : 'paulgiffson@gmail.com',
    mobile : '9003239295',
    socialicons: [
        {
            id: 1,
            name: 'Behance',
            iconname: 'Behance',
            site: 'https://www.behance.net/paulgiffson'
        },
        {
            id: 2,
            name: 'Instagram',
            iconname: 'Instagram',
            site: 'https://www.instagram.com/giffson.co/'
        },
        {
            id: 3,
            name: 'X',
            iconname: 'X',
            site: 'https://x.com/GIFFSON3?s=09'
        },
    
    ],
    copyrights:['Copyright © Scotrand Studio 2024. All Rights Reserved.','Design and Developed By Scotrand Studio.']
  }

  export const metaitems = {
    andriodchrome192 : Images.andriodchrome192,
    andriodchrome512 : Images.andriodchrome512,
    appletouchicon : Images.appletouchicon,
    favicon16 : Images.favicon16,
    favicon32 : Images.favicon32,
    ogimg :Images.ogimg,
    tweetimg :Images.tweetimg,
  }
 export const personalInfo = {
    name: 'Paul Giffson',
    title: 'Front-End Developer & UI UX Designer',
    location: 'Chennai, India',
    image: Images.herosecimg3,
    about:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis ac velit ac mi ultrices aliquam. Proin nec ligula a velit tincidunt posuere.',
    skills: ['HTML', 'CSS', 'JavaScript', 'React'],
    socialLinks: [
      { name: 'GitHub', url: 'https://github.com/yourusername' },
      { name: 'LinkedIn', url: 'https://linkedin.com/in/yourusername' },
    ],
  };
  export const worksData = [
    { name: 'Currency Exchange Price Checker', link: 'https://checkcurrencyprice.netlify.app', thumbnail: Images.currencyExchangeprice },
    { name: 'Development Demo Site', link: 'https://silly-curie-5113e5.netlify.app', thumbnail: Images.Developmentdemo },
    
    // Add more websites as needed
  ];