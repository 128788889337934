const images = {
    logo: require('./logo.svg').default,
    coffeeform: require('./coffeeForm.svg').default,
    contactball: require('./contactBall.svg').default,
    designimg: require('./designImg.svg').default,
    driftarrow: require('./driftArrow.svg').default,
    pexelperfectimg: require('./pexelPerfectDevop.svg').default,
    pokeball: require('./pokeBall.svg').default,
    sidedesignimg: require('./sideDesignImg.svg').default,
    trophy: require('./trophy.svg').default,
    blog1: require('./Exploring Modern UI Design Trends.jpg'),
    blog2: require('./Mastering CSS Grid Layout.jpg'),
    blog3: require('./UI UX Best Practices for Mobile Apps.jpg'),
    blog4: require('./Introduction to Front-End Development.jpg'),
    blog5: require('./Creating Seamless User Experiences.jpg'),
    blog6: require('./Advanced JavaScript Techniques.jpg'),
    blog7: require('./The Future of UI UX Design.jpg'),
    blog8: require('./Responsive Design with Tailwind CSS.jpg'),
    blog9: require('./Designing for Accessibility.jpg'),
    blog10: require('./Prototyping Tools for UI UX Designers.jpg'),
    blog11: require('./Integrating Animation in Web Design.jpg'),
    blog12: require('./The Role of Front-End Developers in Agile Teams.jpg'),
    herosecimg1: require('./herosec-img-1.png'),
    herosecimg2: require('./herosec-img-2.png'),
    herosecimg3: require('./herosec-img-3.png'),
    herosecimg4: require('./herosec-img-4.png'),
    herosecimg5: require('./herosec-img-5.png'),
    brandlogo1: require('./logo1.png'),
    brandlogo2: require('./logo2.png'),
    brandlogo3: require('./logo3.png'),
    brandlogo4: require('./logo4.png'),
    brandlogo5: require('./logo5.png'),
    brandlogo6: require('./logo6.png'),
    brandlogo7: require('./logo7.png'),
    brandlogo8: require('./logo8.png'),
    brandlogo9: require('./logo9.png'),
    showcase1: require('./showCase1.png'),
    showcase2: require('./showCase2.png'),
    showcase3: require('./showCase3.png'),
    showcase4: require('./showCase4.png'),
    showcase5: require('./showCase5.png'),
    appletouchicon: require('./favicon/apple-touch-icon.png'),
    andriodchrome192: require('./favicon/android-chrome-192x192.png'),
    andriodchrome512: require('./favicon/android-chrome-512x512.png'),
    favicon16: require('./favicon/favicon-16x16.png'),
    favicon32: require('./favicon/favicon-32x32.png'),
    ogimg: require('./ogimg.png'),
    tweetimg: require('./tweetimg.png'),
    currencyExchangeprice: require('./Currency Exchange Price Checker.png'),
    Developmentdemo: require('./Development Demo Site.png'),
   
}

export default images