import React from 'react'
import { clientcontent } from '../../assets/Contents'
import Ticker from '../common/Ticker'

const ClientSection = () => {
  return (
    <div className='ClientSection'>
      <div className='container mx-auto px-4'>
        <div className="bestat flex justify-end w-full">
        <div className='w-full lg:w-6/12 xl:w-4/12 lg:pt-20 flex items-center flex-row-reverse lg:flex-row gap-8 justify-evenly lg:mr-32 relative'>
             <div>
             <h2 className='mb-4 text-lg sm:text-xl lg:text-2xl xl:text-3xl font-semibold sm:font-medium  text-[#8CA9F5] relative w-fit'>
                {clientcontent.title}
                <span className='w-[50px] h-[50px] absolute rounded-full -top-3 lg:-top-1 -left-4 inline-block bg-[#8CA9F5]/20'></span>
              </h2>
             <ul className='list-none custom-list-disc'>
              {
                clientcontent.description.map((description, index) => (
                  <li className=' text-white mb-3' key={index}>{description}</li>
                ))
              }
             </ul>
             </div>
             <div className='awardimg'>
              <img src={clientcontent.image} alt="companies logo"  loading='lazy'/>
             </div>
             <div className='w-full sm:w-[500px]  sm:h-[500px] absolute -top-1/2 lg:-top-1/2 left-1/2 -z-10 translate-x-[-50%] blur-[60px] from-[#003CDA28] bg-gradient-to-t to-[#191A26]'></div>
            
            </div>
          
          
        </div>
       <Ticker/>
      </div>
    </div>
  )
}

export default ClientSection