import React from 'react';
import { worksData } from '../assets/Contents';



const Works = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-3xl sm:text-5xl lg:text-6xl xl:text-7xl font-semibold text-[#8CA9F5] mb-8">Our Works</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {worksData.map((work, index) => (
          <div key={index} className="bg-gray-800 rounded-lg shadow-md p-4">
            <div className="work-thumbnail mb-4">
              <img src={work.thumbnail} alt={work.name} className="w-full h-auto rounded-md shadow-md" loading='lazy'/>
            </div>
            <h3 className="text-white text-xl font-semibold mb-2">{work.name}</h3>
            <a
              href={work.link}
              target="_blank"
              rel="noopener noreferrer"
              className="text-[#8CA9F5] hover:text-blue-500 transition duration-300"
            >
              Visit Site
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Works;
