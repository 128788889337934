import React from 'react';
import { Link } from 'react-router-dom';
import { blogs } from '../../assets/Contents';



const Post = ({visiblePost, showAll}) => {
  const scrollToSection = (id) => {
    const section = document.querySelector(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }

  };
  return (
    <>
      <div className='Post mt-9'>
        <div className="container mx-auto">
          <div className="post-container px-4">
            <h2 className='mb-4 text-3xl sm:text-5xl lg:text-6xl xl:text-7xl font-semibold sm:font-normal text-[#8CA9F5]'>
              Our Stories
            </h2>
            <div className="flex items-center flex-wrap lg:flex-nowrap gap-2 justify-between mb-12">
              <p className='w-full xl:w-fit md:whitespace-nowrap text-white font-medium text-xl lg:text-2xl opacity-85 mb-4 lg:mb-0'>
                Everything for Designers, Developers and Readers Only.
              </p>
              <div className='flex items-center flex-wrap gap-8 md:justify-between w-full'>
                <Link className='transition-all duration-200 bg-transparent px-4 text-[#8CA9F5] py-2 rounded-xl border-2 border-[#8CA9F5] lg:border-transparent hover:border-[#8CA9F5] text-xl' to='/' onClick={()=> {scrollToSection('#contact')
                    scrollToSection('#contact');
                }}>
                  Let's Talk
                </Link>
                
               <Link className='transition-all duration-200 px-4 py-2 rounded-xl text-[#1B1818] bg-[#8CA9F5] border-2 border-transparent hover:border-[#8CA9F5] hover:bg-transparent hover:text-[#8CA9F5] text-xl' to={`${showAll ? '/' : '/blogs'}`}>
                  {showAll ? 'Go Back' : 'See all'}
                </Link>
                
              </div>
            </div>
            <div className='post-container flex flex-wrap'>
              {blogs.slice(0, showAll ? blogs.length : visiblePost).map((blog) => (
                
                  
                  <div className="post w-full sm:w-1/2 lg:w-1/3 px-0 sm:px-4 mb-8"  key={blog.id}>
                    <div className="post-img w-full overflow-hidden rounded-md md:h-[200px] mb-6">
                      <img src={blog.image} alt={blog.title} className='w-full h-full object-cover' loading='lazy' />
                    </div>
                    <div className='post-content w-full'>
                      <p className='text-[#8CA9F5] mb-2'>{`Posted on ${blog.date}`}</p>
                      <h4 className='text-white transition-all duration-200 cursor-pointer hover:text-[#8CA9F5] text-xl lg:text-2xl mb-2 sm:line-clamp-1'>
                        <Link to={`/blog/${blog.id}`}>{blog.title}</Link>
                      </h4>
                      <p className='text-base lg:text-lg font-light text-white opacity-80 line-clamp-2'>{blog.description}</p>
                      <Link className='inline-block mt-6 transition-all duration-200 px-4 py-2 rounded-xl text-[#1B1818] bg-[#8CA9F5] border-2 border-transparent hover:border-[#8CA9F5] hover:bg-transparent hover:text-[#8CA9F5] text-xl' to={`/blog/${blog.id}`}>
                        Read more
                      </Link>
                    </div>
                  </div>
             
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Post;
