import React from 'react'

const Disclaimer = () => {
  return (
    <div className='Disclaimer overflow-hidden'>
    <div className="container mx-auto px-4">
      <div className="disclaimer-content pt-16 lg:pt-48">
        <h2 className='mb-4 text-2xl sm:text-4xl lg:text-5xl xl:text-6xl font-semibold sm:font-normal text-[#8CA9F5]'>
          Disclaimer
        </h2>
        <p className='text-md sm:text-lg lg:text-xl xl:text-2xl text-white opacity-80 md:font-light mb-4'>
          The information provided by SCOTRAND us on this website is for general informational purposes only. All information on the site is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the site.
        </p>
        <h3 className='mb-4 text-xl sm:text-2xl lg:text-3xl xl:text-4xl font-semibold sm:font-normal text-[#8CA9F5]'>
          External Links
        </h3>
        <p className='text-md sm:text-lg lg:text-xl xl:text-2xl text-white opacity-80 md:font-light mb-4'>
          The Site may contain (or you may be sent through the Site) links to other websites or content belonging to or originating from third parties or links to websites and features. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or completeness by us.
        </p>
        <h3 className='mb-4 text-xl sm:text-2xl lg:text-3xl xl:text-4xl font-semibold sm:font-normal text-[#8CA9F5]'>
          Professional Disclaimer
        </h3>
        <p className='text-md sm:text-lg lg:text-xl xl:text-2xl text-white opacity-80 md:font-light mb-4'>
          The Site cannot and does not contain professional advice. The information is provided for general informational and educational purposes only and is not a substitute for professional advice. Accordingly, before taking any actions based upon such information, we encourage you to consult with the appropriate professionals.
        </p>
        <h3 className='mb-4 text-xl sm:text-2xl lg:text-3xl xl:text-4xl font-semibold sm:font-normal text-[#8CA9F5]'>
          Contact Us
        </h3>
        <p className='text-md sm:text-lg lg:text-xl xl:text-2xl text-white opacity-80 md:font-light mb-4'>
          If you have any questions or concerns about this Disclaimer, please contact us at: <a href="mailto:giffson.scotrand@gmail.com" className="text-[#8CA9F5]">giffson.scotrand@gmail.com</a>
        </p>
      </div>
    </div>
  </div>
  )
}

export default Disclaimer