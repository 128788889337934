import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div className='PrivacyPolicy overflow-hidden'>
      <div className="container mx-auto px-4">
        <div className="privacy-content pt-16 lg:pt-48">
          <h2 className='mb-4 text-2xl sm:text-4xl lg:text-5xl xl:text-6xl font-semibold sm:font-normal text-[#8CA9F5]'>
            Privacy Policy
          </h2>
          <p className='text-md sm:text-lg lg:text-xl xl:text-2xl text-white opacity-80 md:font-light mb-4'>
            This Privacy Policy describes how SCOTRAND will collects, uses, and discloses your Personal Information when you visit or interact with our website.
          </p>
          <h3 className='mb-4 text-xl sm:text-2xl lg:text-3xl xl:text-4xl font-semibold sm:font-normal text-[#8CA9F5]'>
            Information We Collect
          </h3>
          <p className='text-md sm:text-lg lg:text-xl xl:text-2xl text-white opacity-80 md:font-light mb-4'>
            We collect information about you in a variety of ways. The information we may collect on the Site includes:
          </p>
          <ul className='list-disc ml-5 text-md sm:text-lg lg:text-xl xl:text-2xl text-white opacity-80 md:font-light mb-4'>
            <li>Personal Data</li>
            <li>Derivative Data</li>
            <li>Financial Data</li>
            <li>Mobile Device Data</li>
          </ul>
          <h3 className='mb-4 text-xl sm:text-2xl lg:text-3xl xl:text-4xl font-semibold sm:font-normal text-[#8CA9F5]'>
            How We Use Your Information
          </h3>
          <p className='text-md sm:text-lg lg:text-xl xl:text-2xl text-white opacity-80 md:font-light mb-4'>
            Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Site to create and manage your account, deliver targeted advertising, and for other purposes.
          </p>
          <h3 className='mb-4 text-xl sm:text-2xl lg:text-3xl xl:text-4xl font-semibold sm:font-normal text-[#8CA9F5]'>
            Sharing Your Information
          </h3>
          <p className='text-md sm:text-lg lg:text-xl xl:text-2xl text-white opacity-80 md:font-light mb-4'>
            We may share information we have collected about you in certain situations. Your information may be disclosed as follows:
          </p>
          <ul className='list-disc ml-5 text-md sm:text-lg lg:text-xl xl:text-2xl text-white opacity-80 md:font-light mb-4'>
            <li>By Law or to Protect Rights</li>
            <li>With Your Consent</li>
            <li>Other Reasons</li>
          </ul>
          <h3 className='mb-4 text-xl sm:text-2xl lg:text-3xl xl:text-4xl font-semibold sm:font-normal text-[#8CA9F5]'>
            Contact Us
          </h3>
          <p className='text-md sm:text-lg lg:text-xl xl:text-2xl text-white opacity-80 md:font-light mb-4'>
            If you have questions or comments about this Privacy Policy, please contact us at: <a href="mailto:giffson.scotrand@gmail.com" className="text-[#8CA9F5]">giffson.scotrand@gmail.com</a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy