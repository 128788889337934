import React, { useEffect, useRef } from 'react';
import { clientcontent } from '../../assets/Contents';
import images from '../../assets/images';

const Ticker = () => {
  const tickerRef = useRef(null);

  useEffect(() => {
    const ticker = tickerRef.current;

    if (!ticker) return;

    const tickerTrack = ticker.querySelector('.ticker-track');
    // const tickerTrackWidth = tickerTrack.clientWidth;

   
    
    
    ticker.appendChild(tickerTrack.cloneNode(true));
     
    // console.log(clonedTrack);

    return () => {
     ticker.removeChild(tickerTrack)
    };

  }, []);

  return (
    <div className="ticker relative">
       <span className="absolute -top-[150px] left-[60px]  hidden flex-col gap-4 items-end lg:flex xl:flex ">
                {/* <div className="bg-transparent lg:inline-block lg:bg-radial from-teal-300 via-gray-100 to-blue-300 blur-[10px] rounded-full rotate-[16.62deg]">
                  \\\\
                </div> */}
                <span className='text-white text-2xl hidden lg:block '>Peek Clients</span>
                <img src={images.driftarrow} alt="driftarrow " className=' scale-x-[-1] w-[80px]'  loading='lazy' />
              </span>
              <h2 className='mt-4 text-lg sm:text-xl lg:text-2xl xl:text-3xl font-semibold sm:font-medium  w-full block lg:hidden xl:hidden text-[#8CA9F5] '>Peek Clients
              </h2>
              <div className='ticker-container flex items-center w-full overflow-hidden gap-2 ticker-gradient mt-10 xl:mt-20 ' ref={tickerRef}>
             
        <div className='ticker-track flex animate-infinite_scroll whitespace-nowrap flex-shrink-0 gap-2 '>
          {clientcontent.clientlogos.map((logo) => (
            <img key={logo.id} src={logo.image} alt={logo.name} className="w-max h-auto grayscale" loading='lazy' />
          ))}
        </div>
      </div>
    </div>
   
  );
};

export default Ticker;
