import React, { useEffect } from 'react'
import Post from './BlogPost'

const Blogs = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top when component mounts
      }, []); // Trigger scroll when id changes
  return (
    <div className='Blogs'>
       <Post showAll={true} />
    </div>
  )
}

export default Blogs