import React, { StrictMode } from 'react'
import ReactDom from 'react-dom/client'
import './index.css'
import App from './App'
import {HelmetProvider} from 'react-helmet-async'

const root = ReactDom.createRoot(document.getElementById('root'))
root.render(

<StrictMode>
    <HelmetProvider>
    <App />
    </HelmetProvider>
 
</StrictMode>

)